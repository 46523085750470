import * as R from 'ramda';
import {
  getUserCellPhoneNumber,
  getUserWorkPhoneNumber,
  getUserFaxPhoneNumber,
  removePropDeep,
} from 'poly-utils';
import {
  LOGIN_APP_PERMISSION,
  MOBILE_APP_NAME,
  SITE_APP_NAME,
  getAccessItemTypeValuesByKey,
} from 'poly-security';

import { INACTIVE } from '../../constants/staff.js';
import { phoneFormatter } from '../formatters/index.js';

// formatPhoneByGetter :: Function -> User -> FormattedPhoneNumber
const formatPhoneByGetter = (getter) =>
  R.compose(
    phoneFormatter,
    R.ifElse(() => !!getter, getter, R.identity),
  );

// getUserWorkPhone :: User -> FormattedPhoneNumber
export const getUserWorkPhone = formatPhoneByGetter(getUserWorkPhoneNumber);

// getUserWorkPhone :: User -> FormattedPhoneNumber
export const getUserCellPhone = formatPhoneByGetter(getUserCellPhoneNumber);

// getUserWorkPhone :: User -> FormattedPhoneNumber
export const getUserFaxPhone = formatPhoneByGetter(getUserFaxPhoneNumber);

/**
 * checkEmailError :: GraphQLErrorString -> Boolean
 */
export const checkEmailError = R.equals(
  'GraphQL error: Email already assigned to another user',
);

/**
 * checkInactive :: [Technicians] -> [FilteredTechnicians]
 */
export const checkInactive = R.reject(
  R.both(
    R.pathEq(['technicianStats', 'completedCount'], 0),
    R.propEq('status', INACTIVE),
  ),
);

// AllowedAppNames :: [ClientAppName] -> [APP_TYPES]
const AllowedAppNames = [SITE_APP_NAME, MOBILE_APP_NAME];

// isAllowedByClientAppAIT :: UserGroupAccessItem -> Boolean
const isAllowedByClientAppAIT = R.compose(
  R.any(R.includes(R.__, AllowedAppNames)),
  getAccessItemTypeValuesByKey('clientApp'),
);

/**
 * prepareUserTypes :: AccessRoles -> [UserTypes]
 */
export const prepareUserTypes = R.pipe(
  removePropDeep('__typename'),
  R.filter(
    R.propSatisfies(
      R.pipe(
        R.find(
          R.both(
            R.propEq('permission', LOGIN_APP_PERMISSION),
            isAllowedByClientAppAIT,
          ),
        ),
        R.complement(R.isNil),
      ),
      'accessItems',
    ),
  ),
  R.sortBy(R.prop('name')),
  R.map(({ _id, name }) => ({ value: _id, label: name })),
);
