import * as R from 'ramda';
import { toast } from 'react-toastify';
import { isNilOrEmpty } from 'poly-utils';

import {
  AddStaffSuccess,
  EditStaffEnrollmentResent,
  EditStaffSuccess,
  GeneralError,
} from '../../constants/alerts.js';
import { ACCESS_ROLE_IDS } from '../../constants/access-roles.js';
import { setStaffModal } from '../../redux/actions/index.js';
import { checkEmailError } from '../../utils/staff/index.js';
import { getValue } from '../../util/general.js';

export const isTargetUserTechnician = (accessRoleId) => {
  const { technicianLead, technician } = ACCESS_ROLE_IDS;

  return [technicianLead, technician].includes(accessRoleId);
};

export const selectUserStatus =
  ({ setUserStatus }) =>
  (status) =>
    setUserStatus(R.prop('value', status));

export const selectType =
  ({
    isAdd,
    errors,
    setType,
    setError,
    selectedUser,
    shouldRefetch,
    validateField,
    setShouldRefetch,
  }) =>
  (type) => {
    const value = R.prop('value', type);
    setType(value);

    if (!isAdd && selectedUser.accessRole._id !== value) {
      setShouldRefetch(true);
    } else if (shouldRefetch) {
      setShouldRefetch(false);
    }

    validateField('type', value);

    if (!isTargetUserTechnician(value)) {
      setError({
        ...errors,
        type: R.isNil(type) ? 'Please Fill Out This Field' : null,
      });
    }
  };

export const selectTechnicianSkills =
  ({ setTechnicianSkills, validateField }) =>
  (skills) => {
    setTechnicianSkills(skills.map(R.prop('value')));
    validateField('technicianSkills', skills);
  };

// getStaffUserByMutationResponse :: {createUser: User, updateUser: User} -> User
const getStaffUserByMutationResponse = R.either(
  R.prop('createUser'),
  R.prop('updateUser'),
);
// prepareUserProfilePhones :: String -> String
const prepareUserProfilePhone = R.when(isNilOrEmpty, R.always(null));

export const handleSubmitStaffUser =
  ({
    type,
    isAdd,
    validate,
    setError,
    dispatch,
    avatarUrl,
    setLoading,
    userStatus,
    selectedUser,
    areSkillsEnabled,
    technicianSkills,
    clientId,
    resendUserEnrollmentEmail,
    handleOpenStaffSidebar,
    ...props
  }) =>
  async (event) => {
    event.preventDefault();
    setLoading(true);

    const firstName = getValue('firstName', event);
    const lastName = getValue('lastName', event);
    const email = getValue('email', event);
    const officePhone = getValue('officePhone', event);
    const mobile = getValue('mobile', event);

    const errors = validate({
      firstName,
      lastName,
      type,
      email,
      officePhone,
      mobile,
      ...(areSkillsEnabled && { technicianSkills }),
    });

    if (errors.isInvalid) {
      setLoading(false);
      return;
    }

    const user = {
      // clientId of a current logged in user
      roleContext: { clientId },
      firstName,
      lastName,
      accessRoleId: type,
      status: userStatus,
      emails: [email],
      profile: {
        cellPhoneNumber: prepareUserProfilePhone(mobile),
        workPhoneNumber: prepareUserProfilePhone(officePhone),
      },
      ...(!isAdd && { userId: selectedUser._id }),
      ...(areSkillsEnabled && { technicianSkills }),
    };

    const mutation = isAdd ? props.createUser : props.updateUser;

    try {
      const { data } = await mutation(user);

      toast.success(isAdd ? AddStaffSuccess : EditStaffSuccess);

      if (
        !isAdd &&
        selectedUser.isSignedUp === false &&
        selectedUser.email !== email
      ) {
        await resendUserEnrollmentEmail({ userId: selectedUser._id });
        toast.success(EditStaffEnrollmentResent);
      }
      dispatch(setStaffModal(null));

      const staffUser = getStaffUserByMutationResponse(data);

      handleOpenStaffSidebar(staffUser, R.always(areSkillsEnabled));
    } catch (error) {
      setLoading(false);
      if (checkEmailError(error.message)) {
        setError({ email: 'This email address is already in use' });
      }
      setError({ server: error.message });
      toast.error(GeneralError);
    }
  };
