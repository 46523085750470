import { gql } from '@apollo/client';

export const getUserRoles = gql`
  query getUserRoles {
    accessRoles {
      _id
      name
      accessItems {
        permission
        types {
          clientApp {
            equals
            flippedContains
          }
        }
      }
    }
  }
`;
