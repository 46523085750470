import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Heading,
  Icon,
  IconButton,
  Input,
  Modal,
  Pip,
  S,
} from 'poly-book';

import Select from '../select/index.js';
import ServerError from '../server-error/index.js';
import InputNumber from '../input-number/index.js';
import ButtonLoader from '../loader/button.js';
import { STATUS_COLORS } from '../../constants/staff.js';
import { formatPlaceholder } from '../../util/select.js';
import { Rows } from '../../util/forms/index.js';
import { skillsSelect } from './fields/skills-select.js';

const ModalGrid = Modal.Item.withComponent(Grid);

function TitleWithPip({ label }) {
  return (
    <Pip color={STATUS_COLORS[label]}>
      <S type="title">{label}</S>
    </Pip>
  );
}

TitleWithPip.propTypes = {
  label: PropTypes.string.isRequired,
};

function SimpleTitle({ label }) {
  return (
    <S type="content" title={label}>
      {label}
    </S>
  );
}

SimpleTitle.propTypes = {
  label: PropTypes.string.isRequired,
};

function StaffModal({
  type,
  isAdd,
  errors,
  loading,
  onChange,
  onSubmit,
  userTypes,
  userStatus,
  selectType,
  closeModal,
  resetError,
  userStatuses,
  selectedUser,
  validateOnBlur,
  technicianSkills,
  areSkillsEnabled,
  selectUserStatus,
  userTypesLoading,
  selectTechnicianSkills,
}) {
  return (
    <Modal show>
      <Modal.Item margin={30}>
        <Heading>
          <Heading.h3 lighter>
            {isAdd ? 'Add' : 'Edit'} Staff Profile
          </Heading.h3>
          <IconButton onClick={closeModal}>
            <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
          </IconButton>
        </Heading>
      </Modal.Item>
      <ServerError error={R.prop('server', errors)} style={{ maxWidth: 430 }} />
      <form {...{ onSubmit }}>
        <ModalGrid columns="115px minmax(200px, 320px)" margin={30} simple>
          <Rows
            items={[
              {
                title: { value: 'Status', props: ['required'] },
                item: (
                  <Select
                    value={userStatus}
                    onChange={selectUserStatus}
                    options={userStatuses}
                    optionRenderer={TitleWithPip}
                    clearable={false}
                    disabled={isAdd}
                  />
                ),
              },
              {
                title: { value: 'First Name', props: ['required'] },
                error: R.prop('firstName', errors),
                item: (
                  <Input
                    name="firstName"
                    placeholder="First Name"
                    defaultValue={R.prop('firstName', selectedUser)}
                    invalid={!!R.prop('firstName', errors)}
                    onBlur={validateOnBlur}
                    onFocus={resetError}
                  />
                ),
              },
              {
                title: { value: 'Last Name', props: ['required'] },
                error: R.prop('lastName', errors),
                item: (
                  <Input
                    name="lastName"
                    placeholder="Last Name"
                    defaultValue={R.prop('lastName', selectedUser)}
                    invalid={!!R.prop('lastName', errors)}
                    onBlur={validateOnBlur}
                    onFocus={resetError}
                  />
                ),
              },
              {
                title: { value: 'Office' },
                error: R.prop('officePhone', errors),
                item: (
                  <InputNumber
                    name="officePhone"
                    type="tel"
                    defaultValue={R.prop('officePhone', selectedUser)}
                    invalid={!!R.prop('officePhone', errors)}
                    placeholder="Office Phone"
                    format="(###) ###-####"
                    mask="_"
                    onBlur={validateOnBlur}
                    onFocus={resetError}
                  />
                ),
              },
              {
                title: { value: 'Mobile' },
                error: R.prop('mobile', errors),
                item: (
                  <InputNumber
                    name="mobile"
                    type="tel"
                    defaultValue={R.prop('mobile', selectedUser)}
                    invalid={!!R.prop('mobile', errors)}
                    placeholder="Mobile"
                    format="(###) ###-####"
                    mask="_"
                    onBlur={validateOnBlur}
                    onFocus={resetError}
                  />
                ),
              },
              {
                title: {
                  value: 'Email',
                  props: ['required'],
                },
                error: R.prop('email', errors),
                item: (
                  <Input
                    name="email"
                    type="email"
                    defaultValue={R.prop('email', selectedUser)}
                    placeholder="Email"
                    invalid={!!R.prop('email', errors)}
                    {...{ onChange }}
                  />
                ),
              },
              {
                title: {
                  value: 'Type',
                  props: ['required'],
                },
                error: R.prop('type', errors),
                item: (
                  <Select
                    value={type}
                    onChange={selectType}
                    placeholder={formatPlaceholder('Type')}
                    options={userTypes}
                    isLoading={userTypesLoading}
                    invalid={!!R.prop('type', errors)}
                    optionRenderer={SimpleTitle}
                  />
                ),
              },
              skillsSelect({
                areSkillsEnabled,
                selectTechnicianSkills,
                errors,
                technicianSkills,
              }),
            ]}
          />
        </ModalGrid>
        <Modal.Buttons>
          <Button
            type="reset"
            mode="gray"
            onClick={closeModal}
            disabled={loading}
          >
            <S type="title">Cancel</S>
          </Button>
          <Button type="submit" mode="orange" disabled={loading}>
            {loading && <ButtonLoader />}
            <S type="title">{isAdd ? 'Add Profile' : 'Save Changes'}</S>
          </Button>
        </Modal.Buttons>
      </form>
    </Modal>
  );
}

const defaultOptionType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

const defaultSelectType = PropTypes.arrayOf(defaultOptionType);

StaffModal.propTypes = {
  type: PropTypes.string,
  userStatus: PropTypes.string,
  userTypes: defaultSelectType,
  userStatuses: defaultSelectType,
  isAdd: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectType: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
  validateOnBlur: PropTypes.func.isRequired,
  areSkillsEnabled: PropTypes.bool.isRequired,
  userTypesLoading: PropTypes.bool.isRequired,
  selectUserStatus: PropTypes.func.isRequired,
  selectTechnicianSkills: PropTypes.func.isRequired,
  errors: PropTypes.shape({ server: PropTypes.string }),
  technicianSkills: PropTypes.arrayOf(PropTypes.string),
  selectedUser: PropTypes.shape({
    mobile: PropTypes.string,
    fullName: PropTypes.string,
    officePhone: PropTypes.string,
    email: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }),
};

StaffModal.defaultProps = {
  type: '',
  errors: {},
  selectedUser: null,
  technicianSkills: [],
};

export default StaffModal;
