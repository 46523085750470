import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CREATE_USER_PERMISSION } from 'poly-security';

import { AbsoluteLoader } from 'poly-site-ui';
import { EditStaffError } from '../../constants/alerts.js';
import { ALL_RESULTS } from '../../constants/pagination.js';
import FilterComponent from '../../components/staff/staff-filter.js';
import { checkPermissionByOneAccessItem } from '../../utils/user/index.js';
import {
  setStaffPaginationCurrentPage,
  setStaffPaginationPageSize,
  setStaffPaginationTotal,
  setStaffFilter,
  setStaffModal,
} from '../../redux/actions/index.js';

export default function StaffFilter(props) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const clientId = useSelector((state) => state.user.clientId);

  const activeFilter = useSelector((state) => state.staff.typeFilter);

  const label = useSelector((state) => state.staff.paginationDropdownLabel);

  const onClick = useCallback(
    (filter, total) => {
      if (label === ALL_RESULTS) {
        dispatch(setStaffPaginationPageSize(total));
      }

      dispatch(setStaffFilter(filter));
      dispatch(setStaffPaginationTotal(total));
      dispatch(setStaffPaginationCurrentPage(1));
    },
    [label, dispatch],
  );

  const openAddStaffModal = useCallback(
    () =>
      checkPermissionByOneAccessItem(
        CREATE_USER_PERMISSION,
        user,
        R.pick(['clientId'], user),
      )
        ? dispatch(setStaffModal({ type: 'add' }))
        : toast.error(EditStaffError),
    [user, dispatch],
  );

  if (!clientId) return <AbsoluteLoader />;

  return (
    <FilterComponent
      {...props}
      onClick={onClick}
      activeFilter={activeFilter}
      openAddStaffModal={openAddStaffModal}
      clientId={clientId}
    />
  );
}
