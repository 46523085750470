import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Conversation, Grid, Message, S } from 'poly-book';
import { getFirstLetterOfNames } from 'poly-client-utils';

import { ASSET_MANAGER_ROLE_ID } from 'poly-security';
import { Loader, renderButton } from './common.js';
import { OpenStaffSidebarUsername } from '../../../../utils/wrappers.js';
import { noDataToDisplay } from '../../../../util/general.js';

function Technician({
  _id,
  firstName,
  lastName,
  phone,
  email,
  onButtonClick,
  selected,
  hover,
  setHover,
  accessRoleId,
}) {
  const onClick = onButtonClick(
    {
      _id,
      firstName,
      lastName,
      email,
      phone,
      accessRoleId,
    },
    !!selected,
  );

  return (
    <Grid.Row>
      <Grid.Cell vertical="center">
        <Message>
          <Message.Header
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            {...{ onClick }}
          >
            <OpenStaffSidebarUsername {...{ firstName, lastName, _id }}>
              <Avatar
                type="text"
                content={getFirstLetterOfNames(firstName, lastName)}
              />
            </OpenStaffSidebarUsername>
          </Message.Header>
          <Message.Content>
            <S type="title" textColor="#6f7a9f">
              {accessRoleId === ASSET_MANAGER_ROLE_ID
                ? 'Asset Manager'
                : 'Technician'}
            </S>
          </Message.Content>
        </Message>
      </Grid.Cell>
      <Grid.Cell vertical="center">
        <S type="content" bold>
          {phone}
        </S>
        <br />
        <S type="content" bold>
          {email}
        </S>
      </Grid.Cell>
      <Grid.Cell vertical="center">
        {renderButton(selected, onClick, hover)}
      </Grid.Cell>
    </Grid.Row>
  );
}

Technician.propTypes = {
  _id: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  setHover: PropTypes.func.isRequired,
  hover: PropTypes.bool.isRequired,
  selected: PropTypes.bool,
  accessRoleId: PropTypes.string,
};

Technician.defaultProps = {
  selected: false,
  phone: '—',
};

function TechnicianContainer(props) {
  const [hover, setHover] = useState(false);

  return <Technician {...props} hover={hover} setHover={setHover} />;
}

function TechniciansTab({
  allTechnicians,
  projectTechnician,
  handleTechniciansClick,
  addLoading,
}) {
  return (
    <>
      {addLoading && <Loader />}
      {projectTechnician && (
        <>
          <Conversation.Item badge>
            <S type="badge" uppercase>
              Assigned To
            </S>
          </Conversation.Item>
          <Conversation.Item>
            <Grid columns="1fr 1fr 36px" simple>
              <TechnicianContainer
                {...projectTechnician}
                key={projectTechnician._id}
                onButtonClick={handleTechniciansClick}
                selected
              />
            </Grid>
          </Conversation.Item>
        </>
      )}
      <Conversation.Item badge>
        <S type="badge" uppercase>
          Available
        </S>
      </Conversation.Item>
      <Conversation.Item>
        {allTechnicians.length > 0 ? (
          <Grid columns="1fr 1fr 36px" simple>
            {allTechnicians.map((technician) => (
              <TechnicianContainer
                {...technician}
                key={technician._id}
                onButtonClick={handleTechniciansClick}
              />
            ))}
          </Grid>
        ) : (
          noDataToDisplay('Data')()
        )}
      </Conversation.Item>
    </>
  );
}

TechniciansTab.propTypes = {
  projectTechnician: PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
  }),
  allTechnicians: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
    }),
  ),
  handleTechniciansClick: PropTypes.func.isRequired,
  addLoading: PropTypes.bool,
};

TechniciansTab.defaultProps = {
  projectTechnician: null,
  allTechnicians: [],
  addLoading: false,
};

export default TechniciansTab;
