import { gql } from '@apollo/client';

export const getUsersByRoles = gql`
  query getUsersByRoles($input: CollectionSearchParams) {
    searchUsers(input: $input) {
      hits {
        _id
        createdAt
        email
        firstName
        fullName
        lastName
        status
        title
        updatedAt
        profile {
          cellPhoneNumber
        }
        accessRole {
          _id
        }
      }
    }
  }
`;
