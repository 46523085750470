import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FULL_ACCESS_PERMISSION, UPDATE_USER_PERMISSION } from 'poly-security';
import { hasUserPermissionsWithAits } from 'poly-client-utils/src/hooks/useHasUserAccessWithPermission.js';

import { EditStaffError } from '../../constants/alerts.js';
import { StaffSidebar } from '../../components/staff/staff-sidebar.js';
import { getUserCellPhone, getUserWorkPhone } from '../../utils/staff/index.js';
import { checkPermissionByOneAccessItem } from '../../utils/user/index.js';
import { useUserQuery } from '../../hooks/staff.js';
import {
  setStaffSidebarActiveTab as setStaffSidebarActiveTabAction,
  setStaffModal as setStaffModalAction,
} from '../../redux/actions/index.js';

// transformUserFields :: User -> Object
const transformUserFields = (userValue) => ({
  ...R.pick(
    [
      '_id',
      'email',
      'phone',
      'accessRole',
      'activeProjectsCounts',
      'overdueProjectsCounts',
      'lastThirtyProjectsCounts',
      'status',
      'fullName',
      'firstName',
      'lastName',
      'technicianSkills',
      'isSignedUp',
    ],
    userValue,
  ),
  officePhone: getUserWorkPhone(userValue),
  mobile: getUserCellPhone(userValue),
});

const UserStaffSidebar = React.memo(() => {
  const dispatch = useDispatch();

  const staff = useSelector((state) => state.staff.modals.sidebar);

  const { userId, tabs } = staff;

  const { user, loading } = useUserQuery(userId);

  const activeUser = useSelector((state) => state.user);

  const openModal = useCallback(
    () =>
      checkPermissionByOneAccessItem(
        UPDATE_USER_PERMISSION,
        activeUser,
        R.pick(['clientId'], activeUser),
      )
        ? dispatch(
            setStaffModalAction({
              type: 'edit',
              user: transformUserFields(user),
            }),
          )
        : toast.error(EditStaffError),
    [setStaffModalAction, activeUser, user],
  );

  const setSidebarActiveTab = useCallback(
    (tab) => () => dispatch(setStaffSidebarActiveTabAction(tab)),
    [setStaffSidebarActiveTabAction, dispatch],
  );

  const selectedUser = transformUserFields(user);

  if (!userId) return null;

  const isUserWithFullAccess = hasUserPermissionsWithAits(user, [
    FULL_ACCESS_PERMISSION,
  ]);

  return (
    <StaffSidebar
      loading={loading}
      openModal={openModal}
      setSidebarActiveTab={setSidebarActiveTab}
      activeTab={tabs.activeTab}
      tabsNames={tabs.tabsNames}
      selectedUser={selectedUser}
      isAllowedToEdit={!isUserWithFullAccess}
    />
  );
});

export default UserStaffSidebar;
