import * as R from 'ramda';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes, { arrayOf, number, shape, string } from 'prop-types';
import { ACTIVE, STATUSES } from '../../constants/staff.js';
import StaffModal from '../../components/staff/add-edit-modal.js';
import { setStaffModal } from '../../redux/actions/index.js';
import {
  isTargetUserTechnician,
  handleSubmitStaffUser,
  selectTechnicianSkills,
  selectType,
  selectUserStatus,
} from './add-edit-modal-handlers.js';
import {
  useCreateUser,
  useUpdateUserMutation,
  useUserTypesQuery,
  useResendUserEnrollmentEmail,
} from '../../hooks/staff.js';
import useValidation from '../../hooks/useValidation.js';
import { useOpenUserStaffSidebar } from '../../utils/staff/useOpenUserStaffSidebar.js';

// userStatuses :: Statuses -> Object
const userStatuses = R.pipe(
  R.values,
  R.map((value) => ({ value, label: value })),
)(STATUSES);

function AddEditStaffModal(props) {
  const dispatch = useDispatch();

  const { modal, selectedUser, clientId, currentUserClientConfig } = props;

  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [type, setType] = useState('');
  const [technicianSkills, setTechnicianSkills] = useState([]);
  const [userStatus, setUserStatus] = useState(ACTIVE);
  const [loading, setLoading] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [isAdd, setAdd] = useState(false);
  const [areSkillsEnabled, setSkillsEnabled] = useState(false);

  useEffect(() => {
    setSkillsEnabled(
      !!currentUserClientConfig.useTechnicianSkills &&
        isTargetUserTechnician(type),
    );
    setAdd(modal.type === 'add');
  }, [modal, currentUserClientConfig, type]);

  useEffect(() => {
    if (!isAdd && selectedUser) {
      if (selectedUser.status) {
        setUserStatus(selectedUser.status);
      }

      setType(selectedUser.accessRole?._id);
      setAvatarUrl(selectedUser.avatar?.url || null);
      setTechnicianSkills(selectedUser?.technicianSkills || []);
    }
  }, [
    selectedUser,
    setType,
    setUserStatus,
    setAvatarUrl,
    setTechnicianSkills,
    isAdd,
  ]);

  const { userTypes, userTypesLoading } = useUserTypesQuery();

  const { createUser } = useCreateUser();

  const { updateUser } = useUpdateUserMutation(shouldRefetch);

  const { resendUserEnrollmentEmail } = useResendUserEnrollmentEmail();
  const handleOpenStaffSidebar = useOpenUserStaffSidebar();

  const {
    errors,
    resetError,
    setError,
    onChange,
    validateOnBlur,
    validate,
    validateField,
  } = useValidation({
    validationRules: () => ({
      firstName: [{ rule: 'required' }],
      lastName: [{ rule: 'required' }],
      type: [{ rule: 'required' }],
      email: [{ rule: 'email' }, { rule: 'required' }],
      officePhone: [{ rule: 'phone' }],
      mobile: [{ rule: 'phone' }],
      ...(areSkillsEnabled && { technicianSkills: [{ rule: 'required' }] }),
    }),
  });

  const closeModal = useCallback(
    () => dispatch(setStaffModal(null)),
    [dispatch],
  );

  const onSubmitHandler = handleSubmitStaffUser({
    type,
    isAdd,
    validate,
    setError,
    dispatch,
    avatarUrl,
    setLoading,
    userStatus,
    selectedUser,
    areSkillsEnabled,
    technicianSkills,
    clientId,
    createUser,
    updateUser,
    resendUserEnrollmentEmail,
    handleOpenStaffSidebar,
    ...props,
  });

  const selectTypeHandler = selectType({
    isAdd,
    errors,
    setType,
    setError,
    selectedUser,
    shouldRefetch,
    validateField,
    setShouldRefetch,
  });

  const selectTechnicianSkillsHandler = selectTechnicianSkills({
    setTechnicianSkills,
    validateField,
  });

  const selectUserStatusHandler = selectUserStatus({ setUserStatus });

  return (
    <StaffModal
      {...props}
      isAdd={isAdd}
      errors={errors}
      userStatus={userStatus}
      type={type}
      userStatuses={userStatuses}
      loading={loading}
      technicianSkills={technicianSkills}
      selectTechnicianSkills={selectTechnicianSkillsHandler}
      areSkillsEnabled={areSkillsEnabled}
      selectUserStatus={selectUserStatusHandler}
      selectType={selectTypeHandler}
      validateOnBlur={validateOnBlur}
      onChange={onChange}
      closeModal={closeModal}
      selectedUser={selectedUser}
      onSubmit={onSubmitHandler}
      resetError={resetError}
      userTypes={userTypes}
      userTypesLoading={userTypesLoading}
      avatarUrl={avatarUrl}
      createUser={createUser}
      updateUser={updateUser}
      setUserStatus={setUserStatus}
      setAvatarUrl={setAvatarUrl}
      setLoading={setLoading}
      setType={setType}
      setShouldRefetch={setShouldRefetch}
      clientId={clientId}
      validate={validate}
    />
  );
}

export default function (props) {
  const modal = useSelector((state) => state.staff.modals.addEdit);

  const selectedUser = useSelector((state) => state.staff.modals.sidebar.user);

  const clientId = useSelector((state) => state.user.clientId);

  const currentUserClientConfig = useSelector(
    (state) => state.user.clientConfig,
  );

  return (
    modal && (
      <AddEditStaffModal
        {...props}
        modal={modal}
        selectedUser={selectedUser}
        clientId={clientId}
        currentUserClientConfig={currentUserClientConfig}
      />
    )
  );
}

AddEditStaffModal.propTypes = {
  isAdd: PropTypes.bool,
  areSkillsEnabled: PropTypes.bool,
  userTypesLoading: PropTypes.bool,
  currentUserClientConfig: PropTypes.shape({
    noManagerForRWONeeded: PropTypes.bool,
    projectPrioritiesHours: arrayOf(shape({ priority: string, hours: number })),
    site: PropTypes.objectOf(PropTypes.bool),
    supplierSource: PropTypes.string,
    useTechnicianSkills: PropTypes.bool,
    useTechnicianTeams: PropTypes.bool,
  }),
  modal: PropTypes.shape({ type: PropTypes.string }),
  selectedUser: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    isSignedUp: PropTypes.bool,
    status: PropTypes.string,
    _id: PropTypes.string,
    technicianSkills: PropTypes.arrayOf(PropTypes.string),
    mobile: PropTypes.number,
    officePhone: PropTypes.number,
    accessRole: PropTypes.objectOf(PropTypes.string),
    avatar: PropTypes.string,
    url: PropTypes.string,
  }),
  clientId: PropTypes.string,
};
