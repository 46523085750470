import { subDays } from 'date-fns';
import { ensureIsDate } from 'poly-utils';
import {
  ACCESS_ROLE_ID_AIT,
  ACCOUNT_DIRECTOR_ROLE_ID,
  LOGIN_APP_PERMISSION,
  MOBILE_APP_NAME,
  PORTFOLIO_MANAGER_ROLE_ID,
  SITE_ACCOUNTING_ROLE_ID,
  SITE_APP_NAME,
  SITE_MANAGER_ROLE_ID,
  TECHNICIAN_LEAD_ROLE_ID,
  TECHNICIAN_ROLE_ID,
  ASSET_MANAGER_ROLE_ID,
  getSearchAccessItemsQueryByAIT,
  CLIENT_ID_AIT,
  CLIENT_APP_AIT,
} from 'poly-security';
import { UserStatuses, WorkOrderStatus } from 'poly-constants';

const currentDate = new Date();
const activeProjectStatuses = [
  WorkOrderStatus.ACTIVE,
  WorkOrderStatus.IN_PROCESS,
  WorkOrderStatus.ON_HOLD_CLIENT,
  WorkOrderStatus.ON_HOLD_PARTS,
  WorkOrderStatus.ON_HOLD_PROJECT,
];

const requestArray = [
  {
    terms: {
      status: activeProjectStatuses,
    },
  },
];

const additionalUsersQuery = [
  getSearchAccessItemsQueryByAIT(
    CLIENT_APP_AIT,
    [{ term: { 'accessItems.permission': LOGIN_APP_PERMISSION } }],
    [SITE_APP_NAME, MOBILE_APP_NAME],
  ),
];

export const commonUsersQuery = (clientId) => [
  { exists: { field: `profile.${ACCESS_ROLE_ID_AIT}` } },
  clientId
    ? getSearchAccessItemsQueryByAIT(
        CLIENT_ID_AIT,
        additionalUsersQuery,
        clientId,
      )
    : {
        nested: {
          path: 'accessItems',
          query: { bool: { should: additionalUsersQuery } },
        },
      },
];

export const activeProjectsQuery = {
  bool: {
    must: requestArray,
  },
};

export const overdueProjectsQuery = {
  bool: {
    must: requestArray,
    filter: {
      range: {
        endDate: { lt: currentDate },
      },
    },
  },
};

export const lastThirtyProjectsQuery = {
  bool: {
    must: [{ term: { status: WorkOrderStatus.COMPLETED } }],
    filter: {
      range: {
        workCompletionDate: { gt: subDays(ensureIsDate(currentDate), 30) },
      },
    },
  },
};

export const techniciansQuery = (clientId) => ({
  bool: {
    must: [
      ...commonUsersQuery(clientId),
      {
        terms: {
          'profile.accessRoleId': [
            TECHNICIAN_ROLE_ID,
            TECHNICIAN_LEAD_ROLE_ID,
            ASSET_MANAGER_ROLE_ID,
          ],
        },
      },
    ],
  },
});

export const managersQuery = (clientId) => ({
  bool: {
    must: [
      ...commonUsersQuery(clientId),
      {
        terms: {
          'profile.accessRoleId': [
            SITE_MANAGER_ROLE_ID,
            PORTFOLIO_MANAGER_ROLE_ID,
            SITE_ACCOUNTING_ROLE_ID,
            ACCOUNT_DIRECTOR_ROLE_ID,
          ],
        },
      },
    ],
  },
});

export const requestersQuery = { term: { status: UserStatuses.ACTIVE } };

export const allStaffQuery = (clientId) => ({
  bool: { must: commonUsersQuery(clientId) },
});
