import { gql } from '@apollo/client';

import { clientConfigFragment } from './fragments.js';

export const currentUserQuery = gql`
  query currentUser($glCodesInput: ClientGLCodesInput) {
    me {
      _id
      firstName
      lastName
      fullName
      email
      status
      accessRole {
        _id
      }
      accessItems {
        permission
        types {
          clientId {
            equals
            flippedContains
          }
          propertyId {
            equals
            flippedContains
          }
          createdBy {
            equals
            flippedContains
          }
          poAmount {
            equals
            gte
            gt
            lte
            lt
          }
        }
      }
      clients {
        _id
        configs {
          ...clientConfigFragment
        }
        glCodes(input: $glCodesInput) {
          code
          name
        }
      }
    }
  }

  ${clientConfigFragment}
`;
