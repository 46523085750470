import { gql } from '@apollo/client';

export const getTechnicianStats = gql`
  query getTechnicianStats(
    $input: CollectionSearchParams
    $dateRange: DateRangeInput!
  ) {
    searchUsers(input: $input) {
      total
      hits {
        _id
        fullName
        accessRole {
          _id
        }
        status
        technicianStats(dateRange: $dateRange) {
          completedCount
          createdCount
          overdueCount
          avgDaysSpend
          woCompleted
          pmCompleted
        }
      }
    }
  }
`;
