import { gql } from '@apollo/client';

import { userFragment } from '../fragments.js';

export const updateUser = gql`
  mutation updateUser($update: UpdateUserInput!) {
    updateUser(update: $update) {
      ...userFragment
    }
  }

  ${userFragment}
`;

export const updateContactUser = gql`
  mutation updateContactUser($update: UpdateContactUserInput!) {
    updateContactUser(update: $update) {
      ...userFragment
    }
  }

  ${userFragment}
`;

export const resendUserEnrollmentEmail = gql`
  mutation resendUserEnrollmentEmail($input: ResendEnrollmentEmailInput!) {
    resendUserEnrollmentEmail(input: $input) {
      sent
    }
  }
`;
