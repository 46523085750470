import * as R from 'ramda';
import { getUserCellPhoneNumber } from 'poly-utils';

// prepareTechniciansWithoutSelected :: [Technician] -> ID -> [Technician]
export const prepareTechniciansWithoutSelected = R.curry(
  (technicians, selectedId) =>
    R.compose(
      R.map(
        R.converge(R.mergeRight, [
          R.pick(['_id', 'firstName', 'lastName', 'email']),
          R.applySpec({
            phone: getUserCellPhoneNumber,
            accessRoleId: R.path(['accessRole', '_id']),
          }),
        ]),
      ),
      R.reject(R.propEq('_id', selectedId)),
      R.defaultTo([]),
    )(technicians),
);
