import { gql } from '@apollo/client';

import { purchaseOrderFragment } from '../../queries/fragments.js';

export const siteGlobalSearchQuery = gql`
  query SITE_GLOBAL_SEARCH(
    $input: CollectionSearchParams!
    $staffInput: CollectionSearchParams!
  ) {
    searchProjects(input: $input) {
      maxScore
      hits {
        _id
        projectId
        status
        type
        endDate
        description
        client {
          _id
          name
        }
        property {
          _id
          name
        }
        technicians {
          _id
        }
      }
    }

    searchRecurringProjects(input: $input) {
      maxScore
      hits {
        _id
        projectId
        status
        endDate
        description
        client {
          _id
          name
        }
        property {
          _id
          name
        }
        technicians {
          _id
        }
      }
    }

    searchProperties(input: $input) {
      maxScore
      hits {
        _id
        status
        name
        address {
          formatted_address
        }
        client {
          _id
          name
        }
      }
    }

    searchAssets(input: $input) {
      maxScore
      hits {
        _id
        description
        equipmentType
        serial
      }
    }

    searchSuppliers(input: $input) {
      maxScore
      hits {
        _id
        status
        company {
          name
          address {
            formatted_address
          }
        }
      }
    }

    searchUsers(input: $staffInput) {
      maxScore
      hits {
        _id
        firstName
        lastName
        fullName
        email
        status
        accessRole {
          _id
          name
        }
        profile {
          cellPhoneNumber
          workPhoneNumber
          faxPhoneNumber
        }
      }
    }

    searchPurchaseOrders(input: $input) {
      maxScore
      hits {
        ...purchaseOrderFragment
      }
    }
  }

  ${purchaseOrderFragment}
`;
