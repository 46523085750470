import { gql } from '@apollo/client';

export const createUser = gql`
  mutation createUser($user: CreateUserInput!) {
    createUser(user: $user) {
      _id
      email
      profile {
        cellPhoneNumber
        workPhoneNumber
        faxPhoneNumber
      }
      fullName
      status
      accessRole {
        _id
      }
    }
  }
`;
