import {
  PORTFOLIO_MANAGER_ROLE_ID,
  SITE_ACCOUNTING_ROLE_ID,
  SITE_MANAGER_ROLE_ID,
  TECHNICIAN_LEAD_ROLE_ID,
  TECHNICIAN_ROLE_ID,
} from 'poly-security';

export const ACCESS_ROLE_IDS = {
  portfolioManager: PORTFOLIO_MANAGER_ROLE_ID,
  siteManager: SITE_MANAGER_ROLE_ID,
  technicianLead: TECHNICIAN_LEAD_ROLE_ID,
  technician: TECHNICIAN_ROLE_ID,
  siteAccounting: SITE_ACCOUNTING_ROLE_ID,
};
