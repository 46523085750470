import * as R from 'ramda';

import {
  createAccessItem,
  hasUserAccessWithAi,
  TECHNICIAN_LEAD_ROLE_ID,
  TECHNICIAN_ROLE_ID,
} from 'poly-security';

/**
 * checkPermissionByOneAccessItem :: String -> User -> Boolean
 */
export const checkPermissionByOneAccessItem = R.curry((name, user, types) =>
  hasUserAccessWithAi(createAccessItem(name, types), user),
);

/**
 * isTechnician :: User -> Boolean
 */
export const isTechnician = R.pathSatisfies(
  R.includes(R.__, [TECHNICIAN_LEAD_ROLE_ID, TECHNICIAN_ROLE_ID]),
  ['accessRole', '_id'],
);
